import * as React from 'react';

// MUI Imports
import { Button, Card, CardActions, CardContent, CardMedia, CssBaseline, Grid, Stack, Box, Typography, Container, Avatar, IconButton, Alert, Chip, Fab } from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';

// Icon Imports
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import GitHubIcon from '@mui/icons-material/GitHub';
import LightModeIcon from '@mui/icons-material/LightMode';
import DarkModeIcon from '@mui/icons-material/DarkMode';
import CheckIcon from '@mui/icons-material/Check';
import PendingIcon from '@mui/icons-material/MoreHoriz';

// Copyright text
function Copyright() {
  return (
    <Typography variant="body2" color="text.secondary" align="center">
      {'Copyright © '}Joshua Weston{' '}
      {new Date().getFullYear()}
    </Typography>
  );
}

// Dynamic stuff
const data = { "linkedin": "https://linkedin.com/in/joshua-weston-159573198", "github": "https://github.com/mrbrist", "email": "mailto:portfolio.joshuaweston@gmail.com" }

const uniProjects = [
  { "key": 0, "name": "Rubble And Power", "description": "A Platformer where your life is the battery in your flashlight and you goal is to get to the top", "url": "https://github.com/mrbrist/RubbleAndPower", "image": "img/RubbleAndPower.png", "inProgress": false },
  { "key": 1, "name": "Mug Head", "description": "A demake of Cup Head that was created during a game jam for my Commercial Games Development module, the purpose of this project was to make a game under design limitations as well as focus on game flow", "url": "https://github.com/MarjutA-K/MugHead", "image": "img/MugHead.png", "inProgress": false },
  { "key": 2, "name": "Locked In", "description": "A PSX Horror game that was created during a game jam for my Commercial Games Development module, taking obvious cues from games like Slender", "url": "https://github.com/GamesTech/CGD_NookJam_Inventex", "image": "img/LockedIn.png", "inProgress": false },
  { "key": 3, "name": "Root Odyssey", "description": "A game that was made in 48 hours for the Global Game Jam 2023, it took inspiration from games like Stardew Valley", "url": "https://github.com/mrbrist/GGJ23", "image": "img/RootOdyssey.png", "inProgress": false },
  { "key": 4, "name": "Time Manipulation", "description": 'This was the project that was made for my dissertation "Time Manipulation in Video Games", it was chosen because I wanted to investigate various implementations of time manipulation mechanics in video games', "video": "https://www.youtube.com/watch?v=QazAwUEw76w", "url": "https://github.com/mrbrist/TimeManipulation", "image": "img/TimeManipulation.png", "inProgress": false, "info": "If you would like a copy of my full dissertation, please contact me" },
  { "key": 5, "name": "Burglar Battle", "description": "A cohort game that was made during my Commercial Games development module and involved around 40 people, it is a 2v2 heist game taking inspiration from games like INTRUDER ", "video": "https://youtu.be/v9Ii9DAEfbU", "image": "img/BurglarBattle.png", "inProgress": false, "alert": "This project contains elements that are under NDA and as such the repo is unavailable" },
].reverse();

const personalProjects = [
  { "key": 0, "name": "litenote", "description": "A simple note taking app built with react and electron", "url": "https://github.com/mrbrist/litenote", "image": "img/litenote.png", "inProgress": false },
  { "key": 1, "name": "Umbra", "description": "A roguelite melee combat test game", "url": "https://github.com/mrbrist/Umbra", "image": "img/Umbra.png", "inProgress": false },
  { "key": 2, "name": "Highrise Hunter", "description": "An arcade sniper ballistics implementation", "url": "https://github.com/mrbrist/HighriseHunter", "image": "img/HighriseHunter.png", "inProgress": true },
  { "key": 3, "name": "Sat Tracker", "description": "A satellite tracker built in react", "url": "https://github.com/mrbrist/sat-tracker", "image": "img/SatTracker.png", "inProgress": true, "website": "https://mrbrist.github.io/sat-tracker/" }
].reverse();

// Theme
const darkTheme = createTheme({
  palette: {
    mode: 'dark',
  },
});

const lightTheme = createTheme({
  palette: {
    mode: 'light',
  },
});

// App component
export default function App() {
  const [dark, setDark] = React.useState(true);

  React.useEffect(() => {
    let isDark = window.localStorage.getItem("dark");
    setDark(isDark);
  }, []);

  const handleTheme = () => {
    setDark(!dark);
    window.localStorage.setItem("dark", dark)
  }

  return (
    <ThemeProvider theme={dark ? darkTheme : lightTheme}>
      <CssBaseline />
      <main>
        <Box
          sx={{
            bgcolor: 'background.paper',
            pt: 8,
            pb: 6,
          }}
        >
          <Container maxWidth="sm" align="center">
            <Fab color="primary" aria-label="add" size="medium" onClick={handleTheme} sx={{
              position: "absolute",
              top: 20,
              right: 20,
            }}>
              {dark ? (<LightModeIcon />) : (<DarkModeIcon />)}
            </Fab>
            {/* <IconButton aria-label="Dark Mode" variant="contained" size="large" onClick={handleTheme} sx={{
              position: "fixed",
              top: 10,
              right: 10,
            }}>
              {dark ? (<LightModeIcon />) : (<DarkModeIcon />)}
            </IconButton> */}
            <Avatar
              alt="Joshua Weston"
              src="profile.jpg"
              sx={{ width: 160, height: 160, mb: 4, mt: -2 }}
            />
            <Typography
              component="h1"
              variant="h2"
              align="center"
              color="text.primary"
              gutterBottom
            >
              Joshua Weston
            </Typography>
            <Typography variant="h5" align="center" color="text.secondary" paragraph>
              <Typography variant="h5" sx={{ fontWeight: "bold" }}>My Skills</Typography>
              <Typography variant="subtitle1">Game Development: C#, C++, Unity, Unreal</Typography>
              <Typography variant="subtitle1">Web Development: JavaScript, React, Electron</Typography>

              <Typography variant="h6" sx={{ pt: 2 }}>UWE Games Technology Graduate (BSc Hons)</Typography>
            </Typography>
            <Stack
              direction="row"
              spacing={1}
              justifyContent="center"
            >
              <IconButton aria-label="LinkedIn" size="large" href={data.linkedin}>
                <LinkedInIcon />
              </IconButton>
              <IconButton aria-label="GitHub" size="large" href={data.github}>
                <GitHubIcon />
              </IconButton>
            </Stack>
            <Stack
              sx={{ pt: 2 }}
              direction="row"
              spacing={2}
              justifyContent="center"
            >
              <Button variant="contained" href={data.email}>Contact Me</Button>
            </Stack>
          </Container>
        </Box>

        <Container maxWidth="lg">
          {/* End hero unit */}
          <Typography variant="h5" align="center" color="text.secondary" paragraph>University Projects</Typography>
          <Grid container spacing={4}>
            {uniProjects.map((p) => (
              <Grid item key={p.key} xs={12} sm={6} md={4}>
                <Card
                  sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}
                >
                  <CardMedia
                    component="div"
                    sx={{
                      // 16:9
                      pt: '56.25%',
                    }}
                    image={p.image}
                  />
                  <CardContent sx={{ flexGrow: 1 }}>
                    <Typography gutterBottom variant="h5" component="h2">
                      {p.name}
                    </Typography>
                    <Typography>
                      {p.description}
                    </Typography>
                  </CardContent>
                  {p.alert ? (<Alert severity="error">{p.alert}</Alert>) : null}
                  {p.info ? (<Alert severity="info">{p.info}</Alert>) : null}
                  <CardActions>
                    {p.video ? (<Button href={p.video} target="_blank">Watch</Button>) : null}
                    {p.website ? (<Button href={p.website} target="_blank">View</Button>) : null}
                    {p.url ? (<Button href={p.url} target="_blank">Github</Button>) : null}
                    {p.inProgress ? (<Chip icon={<PendingIcon />} sx={{ marginLeft: "auto", marginRight: "2%" }} color='info' label="In Progress" variant="outlined" />) : (<Chip icon={<CheckIcon />} sx={{ marginLeft: "auto", marginRight: "2%" }} color='success' label="Complete" variant="outlined" />)}
                  </CardActions>
                </Card>
              </Grid>
            ))}
          </Grid>
        </Container>

        <Container maxWidth="lg" sx={{ pt: 5 }}>
          <Typography variant="h5" align="center" color="text.secondary" paragraph>Personal Projects</Typography>
          <Grid container spacing={4}>
            {personalProjects.map((p) => (
              <Grid item key={p.key} xs={12} sm={6} md={4}>
                <Card
                  sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}
                >
                  <CardMedia
                    component="div"
                    sx={{
                      // 16:9
                      pt: '56.25%',
                    }}
                    image={p.image}
                  />
                  <CardContent sx={{ flexGrow: 1 }}>
                    <Typography gutterBottom variant="h5" component="h2">
                      {p.name}
                    </Typography>
                    <Typography>
                      {p.description}
                    </Typography>
                  </CardContent>
                  <CardActions>
                    {p.video ? (<Button href={p.video} target="_blank">Watch</Button>) : null}
                    {p.website ? (<Button href={p.website} target="_blank">View</Button>) : null}
                    {p.url ? (<Button href={p.url} target="_blank">Github</Button>) : null}
                    {p.inProgress ? (<Chip icon={<PendingIcon />} sx={{ marginLeft: "auto", marginRight: "2%" }} color='info' label="In Progress" variant="outlined" />) : (<Chip icon={<CheckIcon />} sx={{ marginLeft: "auto", marginRight: "2%" }} color='success' label="Complete" variant="outlined" />)}
                  </CardActions>
                </Card>
              </Grid>
            ))}
          </Grid>
        </Container>
      </main>

      {/* Footer */}
      <Box sx={{ bgcolor: 'background.paper', p: 6 }} component="footer">
        <Copyright />
      </Box>
      {/* End footer */}

    </ThemeProvider>
  );
}